#main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding: 0 15px;
}

.wrapperarticle {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 860px;
}

.wrapperarticle > span {
    margin-top: 66px;
}

/* .name {
    width: 100%;
    font-family: 'Inter-Black', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 4.5rem;
    line-height: 1;
    font-weight: bolder;
    text-transform: capitalize;
    padding-top: 66px;
    letter-spacing: -2px;
    word-break: break-word;
} */

.essay {
    font-size: 1.1rem;
    line-height: 1.5;
    padding: 15px 5px 66px 5px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
}

.essay:last-child {
    padding: 66px 5px;
    display: flex;
    flex-direction: column;
}

.wrapper > span {
    cursor: pointer;
    text-decoration: none;
}

.wrapperarticle span > a {
    text-decoration: none;
    text-decoration-color: transparent;
    transition: all .5s ease-in-out;
    color: var(--midnight-light);
    cursor: pointer;
}

.wrapperarticle > span > a:hover {
    text-decoration: line-through;
}

.wrapper > span:first-child {
    padding-bottom: 5px;
    color: #b0b0b0;
}

.content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    align-content: center;
    justify-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 25px;
}

.content-section {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-right: 1px solid #d8d8d8;
    align-self: stretch;
}

.content-section:last-child {
    border: none;
}

.content-section-title, .content-section-block {
    font-family: 'Inter-Regular';
    font-size: 1rem;
    text-align: left;
    color: #000000;
}

.content-section-title::before {
    content: '>_ ';
    color: #d8d8d8;
}

.content-section-block {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: var(--midnight-light);
}

.content-section-block div {
    cursor: pointer;
    text-decoration: none;
    text-decoration-color: transparent;
    transition: all .5s ease-in-out;
}

.content-section-block div > a {
    text-decoration: none;
    text-decoration-color: transparent;
    transition: all .5s ease-in-out;
    color: var(--midnight-light);
    cursor: pointer;
}

.content-section-block div > a:hover {
    text-decoration: line-through;
}

.content-section-block div:hover {
    text-decoration: line-through;
}

@media only screen and (max-width: 1100px) {
    .content {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 33px;
    }

    .content-section {
        width: 100%;
        /*max-width: 320px;*/
        border: none;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
	border-bottom: 1px solid #F0F0F0;
	padding: 75px 15px;
	gap: 30px;
	align-items: center;
	justify-content: center;
}

.section {
	display: flex;
	width: 100%;
	max-width: 960px;
	gap: 15px;
}

.section:first-child {
	border-bottom: 1px solid #F0F0F0;
	padding-bottom: 30px;
}

.photo {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	overflow: hidden;
	background-color: #F0F0F0;
	background-image: url('../profile.jpeg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	align-self: center;
}

.text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	gap: 30px;
}

.text:nth-child(1) {
	gap: 15px;
}

.name {
	font-family: "Figtree", serif;
	font-optical-sizing: auto;
	font-weight: 700;
	font-size: clamp(2rem, 8vw, 4rem);
	line-height: 1.1;
	color: #000000;
}

.title {
	font-family: 'Inter-Regular';
	font-size: 1.25rem;
	line-height: 1.1;
	color: var(--midnight-light);
	padding: 0 5px;
}

.description {
    font-family: 'Inter-Light';
	font-size: 1.25rem;
	line-height: 1.25;
	color: #000000;
	padding: 0 5px;
}

.section:not(:first-child) .description {
	font-family: 'Inter-Regular';
}

.section:not(:first-child) .description::before {
	content: '>_ ';
	color: #d8d8d8;
}

.section:not(:first-child) .title {
	font-family: 'Inter-Light';
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    align-content: center;
    justify-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 25px;
}

.content-section {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-right: 1px solid #d8d8d8;
    align-self: stretch;
}

.content-section:last-child {
    border: none;
}

.content-section-title, .content-section-block {
    font-family: 'Inter-Regular';
    font-size: 1rem;
    text-align: left;
    color: #000000;
}

.content-section-title::before {
    content: '>_ ';
    color: #d8d8d8;
}

.content-section-block {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: var(--midnight-light);
}

.content-section-block div {
    cursor: pointer;
    text-decoration: none;
    text-decoration-color: transparent;
    transition: all .5s ease-in-out;
}

div > a {
    text-decoration: none;
    text-decoration-color: transparent;
    transition: all .5s ease-in-out;
    color: var(--midnight-light);
    cursor: pointer;
	font-family: 'Inter-Light';
}

div > a:hover {
    text-decoration: line-through;
}

.content-section-block div:hover {
    text-decoration: line-through;
}

@media only screen and (max-width: 1100px) {
    .content {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 33px;
    }
    
    .content-section {
        width: 100%;
        /*max-width: 320px;*/
        border: none;
    }
}

:root {
    --glacier: #f0f0f0;
    --purple-light: #7b4aee;
    --purple-medium: #5830b4;
    --purple-dark: #422488;
    --sponsors-pink: #ea4aaa;
    --link-blue-light: #4388dc;
    --link-blue-dark: #1269d3;
    --dark-primary: #18181b;
    --dark-secondary: #19171c;
    --midnight-light: #7f8ff4;
}

@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

@font-face {
    font-family: serverless;
    src: url('fonts/serverless/serverless.otf') format('otf'),
         url('fonts/serverless/serverless.woff') format('woff'),
         url('fonts/serverless/serverless.ttf') format('truetype');
}

@font-face {
    font-family: ubuntu-bold;
    src: url('fonts/ubuntu/Ubuntu-Bold.otf') format('otf'),
         url('fonts/ubuntu/Ubuntu-Bold.woff') format('woff'),
         url('fonts/ubuntu/Ubuntu-Bold.ttf') format('truetype');
}

@font-face {
    font-family: ubuntu-regular;
    src: url('fonts/ubuntu/Ubuntu-Regular.otf') format('otf'),
         url('fonts/ubuntu/Ubuntu-Regular.woff') format('woff'),
         url('fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
    font-family: ubuntu-medium;
    src: url('fonts/ubuntu/Ubuntu-Medium.otf') format('otf'),
         url('fonts/ubuntu/Ubuntu-Medium.woff') format('woff'),
         url('fonts/ubuntu/Ubuntu-Medium.ttf') format('truetype');
}

@font-face {
    font-family: ubuntu-light;
    src: url('fonts/ubuntu/Ubuntu-Light.otf') format('otf'),
         url('fonts/ubuntu/Ubuntu-Light.woff') format('woff'),
         url('fonts/ubuntu/Ubuntu-Light.ttf') format('truetype');
}

/* Inter */
@font-face {
	font-family: 'Inter-Thin';
	src: local('fonts/Inter/files/Inter-Thin'),
		url('fonts/Inter/files/Inter-Thin.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-Thin.eot');
	src: url('fonts/Inter/files/Inter-Thin.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-Thin.woff') format('woff'),
		url('fonts/Inter/files/Inter-Thin.ttf') format('truetype');
		font-weight: 100;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-ExtraLight';
	src: local('fonts/Inter/files/Inter-ExtraLight'),
		url('fonts/Inter/files/Inter-ExtraLight.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-ExtraLight.eot');
	src: url('fonts/Inter/files/Inter-ExtraLight.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-ExtraLight.woff') format('woff'),
		url('fonts/Inter/files/Inter-ExtraLight.ttf') format('truetype');
		font-weight: 200;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-Light';
	src: local('fonts/Inter/files/Inter-Light'),
		url('fonts/Inter/files/Inter-Light.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-Light.eot');
	src: url('fonts/Inter/files/Inter-Light.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-Light.woff') format('woff'),
		url('fonts/Inter/files/Inter-Light.ttf') format('truetype');
		font-weight: 300;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-Regular';
	src: local('fonts/Inter/files/Inter-Regular'),
		url('fonts/Inter/files/Inter-Regular.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-Regular.eot');
	src: url('fonts/Inter/files/Inter-Regular.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-Regular.woff') format('woff'),
		url('fonts/Inter/files/Inter-Regular.ttf') format('truetype');
		font-weight: 400;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-Medium';
	src: local('fonts/Inter/files/Inter-Medium'),
		url('fonts/Inter/files/Inter-Medium.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-Medium.eot');
	src: url('fonts/Inter/files/Inter-Medium.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-Medium.woff') format('woff'),
		url('fonts/Inter/files/Inter-Medium.ttf') format('truetype');
		font-weight: 500;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-SemiBold';
	src: local('fonts/Inter/files/Inter-SemiBold'),
		url('fonts/Inter/files/Inter-SemiBold.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-SemiBold.eot');
	src: url('fonts/Inter/files/Inter-SemiBold.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-SemiBold.woff') format('woff'),
		url('fonts/Inter/files/Inter-SemiBold.ttf') format('truetype');
		font-weight: 600;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-Bold';
	src: local('fonts/Inter/files/Inter-Bold'),
		url('fonts/Inter/files/Inter-Bold.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-Bold.eot');
	src: url('fonts/Inter/files/Inter-Bold.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-Bold.woff') format('woff'),
		url('fonts/Inter/files/Inter-Bold.ttf') format('truetype');
		font-weight: 700;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-ExtraBold';
	src: local('fonts/Inter/files/Inter-ExtraBold'),
		url('fonts/Inter/files/Inter-ExtraBold.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-ExtraBold.eot');
	src: url('fonts/Inter/files/Inter-ExtraBold.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-ExtraBold.woff') format('woff'),
		url('fonts/Inter/files/Inter-ExtraBold.ttf') format('truetype');
		font-weight: 800;
		font-display: swap;
		font-style: normal;
}


@font-face {
	font-family: 'Inter-Black';
	src: local('fonts/Inter/files/Inter-Black'),
		url('fonts/Inter/files/Inter-Black.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-Black.eot');
	src: url('fonts/Inter/files/Inter-Black.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-Black.woff') format('woff'),
		url('fonts/Inter/files/Inter-Black.ttf') format('truetype');
		font-weight: 900;
		font-display: swap;
		font-style: normal;
}

* {
    padding: 0;
    margin: 0;
}

body {
    color: #000;
    font-family: monospace;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    background-color: #ffffff;
}

::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background: var(--dark-secondary);
}

::-webkit-scrollbar-thumb {
    background: var(--glacier);
    border: 3px solid var(--dark-secondary);
    border-radius: 6px;
    -webkit-border-radius: 6px;
}

@-webkit-keyframes blink {
    0% {
        color: var(--dark);
    }
    50% {
        color: var(--glacier);
    }
    100% {
        color: var(--dark);
    }
}

@keyframes blink {
    0% {
        color: var(--dark);
    }
    50% {
        color: var(--glacier);
    }
    100% {
        color: var(--dark);
    }
}

.blink {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;

    -webkit-animation-direction: normal;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: blink;
    -webkit-animation-timing-function: ease-in-out;

    animation-direction: normal;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: blink;
    animation-timing-function: ease-in-out;

    cursor: pointer;
    width: 100%;
    text-align: center;
    z-index: 1;
    background: #fff;
}
@font-face {
    font-family: serverless;
    src: url('fonts/serverless/serverless.otf') format('otf'),
         url('fonts/serverless/serverless.woff') format('woff'),
         url('fonts/serverless/serverless.ttf') format('truetype');
}

@font-face {
    font-family: ubuntu-bold;
    src: url('fonts/ubuntu/Ubuntu-Bold.otf') format('otf'),
         url('fonts/ubuntu/Ubuntu-Bold.woff') format('woff'),
         url('fonts/ubuntu/Ubuntu-Bold.ttf') format('truetype');
}

@font-face {
    font-family: ubuntu-regular;
    src: url('fonts/ubuntu/Ubuntu-Regular.otf') format('otf'),
         url('fonts/ubuntu/Ubuntu-Regular.woff') format('woff'),
         url('fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
    font-family: ubuntu-medium;
    src: url('fonts/ubuntu/Ubuntu-Medium.otf') format('otf'),
         url('fonts/ubuntu/Ubuntu-Medium.woff') format('woff'),
         url('fonts/ubuntu/Ubuntu-Medium.ttf') format('truetype');
}

@font-face {
    font-family: ubuntu-light;
    src: url('fonts/ubuntu/Ubuntu-Light.otf') format('otf'),
         url('fonts/ubuntu/Ubuntu-Light.woff') format('woff'),
         url('fonts/ubuntu/Ubuntu-Light.ttf') format('truetype');
}

/* Inter */
@font-face {
	font-family: 'Inter-Thin';
	src: local('fonts/Inter/files/Inter-Thin'),
		url('fonts/Inter/files/Inter-Thin.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-Thin.eot');
	src: url('fonts/Inter/files/Inter-Thin.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-Thin.woff') format('woff'),
		url('fonts/Inter/files/Inter-Thin.ttf') format('truetype');
		font-weight: 100;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-ExtraLight';
	src: local('fonts/Inter/files/Inter-ExtraLight'),
		url('fonts/Inter/files/Inter-ExtraLight.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-ExtraLight.eot');
	src: url('fonts/Inter/files/Inter-ExtraLight.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-ExtraLight.woff') format('woff'),
		url('fonts/Inter/files/Inter-ExtraLight.ttf') format('truetype');
		font-weight: 200;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-Light';
	src: local('fonts/Inter/files/Inter-Light'),
		url('fonts/Inter/files/Inter-Light.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-Light.eot');
	src: url('fonts/Inter/files/Inter-Light.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-Light.woff') format('woff'),
		url('fonts/Inter/files/Inter-Light.ttf') format('truetype');
		font-weight: 300;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-Regular';
	src: local('fonts/Inter/files/Inter-Regular'),
		url('fonts/Inter/files/Inter-Regular.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-Regular.eot');
	src: url('fonts/Inter/files/Inter-Regular.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-Regular.woff') format('woff'),
		url('fonts/Inter/files/Inter-Regular.ttf') format('truetype');
		font-weight: 400;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-Medium';
	src: local('fonts/Inter/files/Inter-Medium'),
		url('fonts/Inter/files/Inter-Medium.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-Medium.eot');
	src: url('fonts/Inter/files/Inter-Medium.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-Medium.woff') format('woff'),
		url('fonts/Inter/files/Inter-Medium.ttf') format('truetype');
		font-weight: 500;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-SemiBold';
	src: local('fonts/Inter/files/Inter-SemiBold'),
		url('fonts/Inter/files/Inter-SemiBold.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-SemiBold.eot');
	src: url('fonts/Inter/files/Inter-SemiBold.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-SemiBold.woff') format('woff'),
		url('fonts/Inter/files/Inter-SemiBold.ttf') format('truetype');
		font-weight: 600;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-Bold';
	src: local('fonts/Inter/files/Inter-Bold'),
		url('fonts/Inter/files/Inter-Bold.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-Bold.eot');
	src: url('fonts/Inter/files/Inter-Bold.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-Bold.woff') format('woff'),
		url('fonts/Inter/files/Inter-Bold.ttf') format('truetype');
		font-weight: 700;
		font-display: swap;
		font-style: normal;
}

@font-face {
	font-family: 'Inter-ExtraBold';
	src: local('fonts/Inter/files/Inter-ExtraBold'),
		url('fonts/Inter/files/Inter-ExtraBold.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-ExtraBold.eot');
	src: url('fonts/Inter/files/Inter-ExtraBold.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-ExtraBold.woff') format('woff'),
		url('fonts/Inter/files/Inter-ExtraBold.ttf') format('truetype');
		font-weight: 800;
		font-display: swap;
		font-style: normal;
}


@font-face {
	font-family: 'Inter-Black';
	src: local('fonts/Inter/files/Inter-Black'),
		url('fonts/Inter/files/Inter-Black.eot?#iefix') format('embedded-opentype');
	src: url('fonts/Inter/files/Inter-Black.eot');
	src: url('fonts/Inter/files/Inter-Black.woff2') format('woff2'),
		url('fonts/Inter/files/Inter-Black.woff') format('woff'),
		url('fonts/Inter/files/Inter-Black.ttf') format('truetype');
		font-weight: 900;
		font-display: swap;
		font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
